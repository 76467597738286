<template>
  <sdPageHeader title="Gift and prize"> </sdPageHeader>
  <Main>
    <sdCards headless>
      <a-form
        :hideRequiredMark="false"
        ref="formRef"
        :model="form"
        :rules="formRules"
      >
        <a-row :gutter="25">
          <a-col :lg="24" :xs="24">
            <a-form-item name="name" label="Name">
              <a-input v-model:value="form.name" placeholder="Enter name" />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :xs="24">
            <a-form-item name="value" label="Value">
              <a-input
                type="number"
                v-model:value="form.value"
                placeholder="Enter value"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="currency" label="Currency">
              <a-input
                v-model:value="form.currency"
                placeholder="Enter currency"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="position" label="Position">
              <a-select
                placeholder="Select position"
                v-model:value="form.position"
              >
                <a-select-option :value="0">After</a-select-option>
                <a-select-option :value="1">Before</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="8" :xs="24">
            <a-form-item name="image" label="Price popup image">
              <a-input
                type="file"
                id="meta-file"
                accept="image/*"
                v-on:change="onImageChange($event, 'image')"
              />
            </a-form-item>
            <div v-if="image_url" class="img-thumb-cls">
              <img alt="Image" :src="image_url" />
            </div>
          </a-col>
          <a-col :lg="8" :xs="24">
            <a-form-item name="second_image" label="Winning popup image">
              <a-input
                type="file"
                id="meta-file"
                accept="image/*"
                v-on:change="onImageChange($event, 'second_image')"
              />
            </a-form-item>
            <div v-if="second_image_url" class="img-thumb-cls">
              <img alt="Image" :src="second_image_url" />
            </div>
          </a-col>
          <a-col :lg="8" :xs="24">
            <a-form-item name="sponsor_logo" label="Sponsor logo">
              <a-input
                type="file"
                id="meta-file"
                accept="image/*"
                v-on:change="onImageChange($event, 'sponsor_logo')"
              />
            </a-form-item>
            <div v-if="sponsor_logo_url" class="img-thumb-cls">
              <img alt="Image" :src="sponsor_logo_url" />
            </div>
          </a-col>
          <a-col :lg="24" :xs="24">
            <a-form-item name="description" label="Description">
              <a-textarea
                v-model:value="form.description"
                :rows="4"
                placeholder="Enter description"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="discount_code" label="Discount Code">
              <a-input
                v-model:value="form.discount_code"
                placeholder="Enter discount code"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="discount_url" label="Discount url">
              <a-input
                type="url"
                v-model:value="form.discount_url"
                placeholder="Enter Discount url"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="discount_expiry_datetime" label="Discount Expiry Datetime">
              <a-date-picker
                :show-time="true"
                @ok="onDateTimeOk"
                @change="dateTimeChange"
                style="min-width: 100%;"
                format="YYYY-MM-DD HH:mm:ss"
                :disabled-date="disabledDate"
                :disabled-time="disabledDateTime"
                v-model:value="discount_expiry_datetime"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-item name="discount_expiry_datetime_label" label="Discount Expiry Datetime Label">
              <a-input
                v-model:value="form.discount_expiry_datetime_label"
                placeholder="Enter Discount Expiry Datetime Label"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :xs="24" v-if="prize_id == ''">
            <a-form-item name="quantity" label="Quantity">
              <a-input
                type="number"
                v-model:value="form.quantity"
                placeholder="Enter Quantity"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :xs="24">
            <sdButton @click="onSubmitPress" type="primary" class="pull-right">
              Save
            </sdButton>
          </a-col>
        </a-row>
      </a-form>
    </sdCards>
  </Main>
</template>
<script>
import { API } from "@/services/api";
import { Main } from "../../styled";
import Notification from "@/services/Notification";
import moment from "moment";

export default {
  name: "setupGiftPrize",
  components: { Main },
  computed: {
    countries: function () {
      return this.$store.getters.countries;
    },
    formRef: function () {
      return this.$refs.formRef;
    },
  },
  data() {
    return {
      image_url: "",
      second_image_url: "",
      sponsor_logo_url: "",
      prize_id: "",
      form: {
        name: "",
        value: "",
        currency: "",
        position: "",
        description: "",
        discount_code: "",
        discount_url: "",
        quantity: "",
        image: null,
        second_image: null,
        sponsor_logo: null,
        discount_expiry_datetime: "",
        discount_expiry_datetime_label: "",
      },
      discount_expiry_datetime: "",
      formRules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter name",
          },
        ],
        value: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter value",
          },
          {
            type: "number",
            trigger: "blur",
            transform: (val) => Number(val),
            message: "Please enter number",
          },
        ],
        currency: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter currency",
          },
        ],
        description: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter description",
          },
        ],
        // quantity: [{
        //   required: true, trigger: 'blur',
        //   message: 'Please enter quantity',
        // },
        // {
        //   type: 'number', trigger: 'blur',transform:(val) => Number(val),
        //   message: 'Please enter number',
        // },
        // ],
      },
    };
  },
  methods: {
    onImageChange(e, name) {
      const file = e?.target?.files[0];
      const blobURL = URL.createObjectURL(file);

      if (name == "image") {
        this.image_url = blobURL;
        this.form.image = file;
      } else if (name == "second_image") {
        this.second_image_url = blobURL;
        this.form.second_image = file;
      } else if (name == "sponsor_logo") {
        this.sponsor_logo_url = blobURL;
        this.form.sponsor_logo = file;
      }
      return null;
    },
    async onSubmitPress() {
      try {
        await this.formRef.validate();
        const fData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
          if (key) {
            fData.append(key, value);
          }
        }
        fData.append("id", this.prize_id);

        const dataRes = await API.createPrizes(fData);
        const { message, status } = dataRes.data;

        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
    async getPriceDetails() {
      const { prize_id = "" } = this.$route.params;
      if (!prize_id) {
        return null;
      }
      try {
        const res = await API.getPriceDetails({
          params: {
            id: prize_id,
          },
        });
        this.prize_id = prize_id;

        const { data, status } = res?.data ?? {};
        if (status) {
          const {
            name = "",
            value = "",
            currency = "",
            position = "",
            description = "",
            discount_code = "",
            discount_url = "",
            quantity = "",
            image = "",
            second_image = "",
            sponsor_logo = "",
            discount_expiry_datetime = "",
            discount_expiry_datetime_label = "",
          } = data;

          this.discount_expiry_datetime = moment(discount_expiry_datetime).isValid() && discount_expiry_datetime != "" ? moment(discount_expiry_datetime) : "";

          this.image_url = image;
          this.second_image_url = second_image;
          this.sponsor_logo_url = sponsor_logo;
          this.form = {
            ...this.form,
            name,
            value: value,
            currency,
            position,
            description,
            discount_code,
            discount_url,
            discount_expiry_datetime: moment(discount_expiry_datetime).isValid() ? moment(discount_expiry_datetime).format("YYYY-MM-DD HH:mm:ss") : "",
            discount_expiry_datetime_label,
            quantity: JSON.stringify(quantity),
          };
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.fetching = false;
      }
    },
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledDate(current) {
      return current && moment().isAfter(moment.utc(current).local(), "date");
    },
    disabledDateTime() {
      let currentHour = 0;
      let currentMinute = 0;
      let currentSecond = 0;
      if (moment().isSame(moment.utc(this.discount_expiry_datetime).local(), "date")) {
        currentHour = moment().hour();
      }
      if (moment().isSame(moment.utc(this.discount_expiry_datetime).local(), "hour")) {
        currentMinute = moment().minute();
      }
      if (moment().isSame(moment.utc(this.discount_expiry_datetime).local(), "minute")) {
        currentSecond = moment().second();
      }
      return {
        disabledHours: () => this.range(0, 24).splice(0, currentHour),
        disabledMinutes: () => this.range(0, 60).splice(0, currentMinute),
        disabledSeconds: () => this.range(0, 60).splice(0, currentSecond),
      };
    },
    dateTimeChange(value, dateString) {
      this.discount_expiry_datetime = value;
      this.form.discount_expiry_datetime = dateString;
    },
    onDateTimeOk(value) {
      this.discount_expiry_datetime = value;
      this.form.discount_expiry_datetime = value.format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {
    this.getPriceDetails();
  },
};
</script>
